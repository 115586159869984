import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 - Nichts gefunden" />
    <h1>404 - Nichts gefunden</h1>
    <div
      style={{
        width: "100%",
        height: 0,
        paddingBottom: "41%",
        position: "relative",
      }}
    >
      <iframe
        src="https://giphy.com/embed/rlsHtd2YC8k0g"
        width="100%"
        height="100%"
        style={{ position: "absolute" }}
        frameBorder="0"
        className="giphy-embed"
        allowFullScreen
        title="404"
      />
    </div>
    <p>
      <a href="https://giphy.com/gifs/funny-robert-downey-jr-iron-man-rlsHtd2YC8k0g">
        via GIPHY
      </a>
    </p>
  </Layout>
)

export default NotFoundPage
